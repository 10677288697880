/* v8 ignore start */
/* eslint-disable import/no-named-as-default-member -- We need the i18n default member */
// eslint-disable-next-line import/no-named-as-default -- We need this default export
import i18n, { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import ICU from "i18next-icu";
import LocizeBackend from "i18next-locize-backend";
import { locizePlugin } from "locize";
import LastUsed from "locize-lastused";

import { Accessor, createEffect, createSignal } from "solid-js";
import { config } from "./index";
import { BCP47, getLanguageLocale } from "../utilities/defaultLocales";

const locizeOptions = {
    projectId: config.locizeProjectId,
    referenceLng: "en-GB",
    fallbackLng: "en-GB",
};

const httpOptions: HttpBackendOptions = {
    loadPath: (lngs: string[], namespaces: string[]): string => {
        const lng = lngs[0];
        const ns = namespaces[0];

        return `/locales/${lng}/${ns}.json?tag=${config.version.hash}`;
    },
};

const [loaded, setLoaded] = createSignal(false);

const sharedOptions: InitOptions = {
    /**
     * NOTE TO MAINTAINER.
     *
     * These values should match the settings in locize.
     */

    load: "currentOnly",
    fallbackLng: (code) => getLanguageLocale(code as BCP47).locale,
    defaultNS: "general",
    ns: ["general"],

    /**
     * END OF LOCIZE SETTINGS AREA.
     */

    cleanCode: true,
    lowerCaseLng: false, // Needed to align with BCP47
    // keys with . in them are not recognized if this option is not set to false
    keySeparator: false,
    interpolation: {
        escapeValue: true, // As long as SolidJS used innerHTML, escape the values
    },
};

createEffect(() => {
    if (!loaded()) {
        if (!locizeOptions.projectId) {
            // Use the production version, where we load locally
            void i18n
                // Uses data formatting
                .use(ICU)
                // detect user language
                // learn more: https://github.com/i18next/i18next-browser-languageDetector
                .use(LanguageDetector)
                // Use chained
                .use(ChainedBackend)
                // Init for http backend
                .init({
                    debug: false,
                    saveMissing: false,
                    ...sharedOptions,
                    backend: {
                        // @ts-expect-error: The variable window.noTranslations is set by the cucumber tests.
                        backends: (config.debug.disableNLS || window.noTranslations) ? undefined : [HttpBackend],
                        backendOptions: [httpOptions],
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } as any,
                } as InitOptions, () => { setLoaded(true); });
        } else {
            void i18n
                // Uses data formatting
                .use(ICU)
                // i18next-locize-backend
                // loads translations from your project, saves new keys to it (saveMissing: true)
                // https://github.com/locize/i18next-locize-backend
                .use(LocizeBackend)
                // locize-lastused
                // sets a timestamp of last access on every translation segment on locize
                // -> safely remove the ones not being touched for weeks/months
                // https://github.com/locize/locize-lastused
                .use(LastUsed)
                // locize-editor
                // InContext Editor of locize ?locize=true to show it
                // https://github.com/locize/locize-editor
                .use(locizePlugin)
                // detect user language
                // learn more: https://github.com/i18next/i18next-browser-languageDetector
                .use(LanguageDetector)
                // init i18next
                // for all options read: https://www.i18next.com/overview/configuration-options
                .init({
                    debug: config.debug.i18n,
                    ...sharedOptions,
                    backend: {
                        ...locizeOptions,
                        // @ts-expect-error: The variable window.noTranslations is set by the cucumber tests.
                        projectId: (config.debug.disableNLS || window.noTranslations)
                            ? undefined
                            : locizeOptions.projectId,
                    },
                    locizeLastUsed: locizeOptions,
                    ...locizeOptions,
                } as InitOptions, () => { setLoaded(true); });
        }
    }
});

/**
 * Translations load signal.
 *
 * @returns Accessor signal that indicates whether i18n is loaded.
 */
export const translationsLoaded: Accessor<boolean> = loaded;
