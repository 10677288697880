import { Button, Dialog, DialogContent, DialogTitle, Modal, useMediaQuery, useTheme } from "@suid/material";
import styled from "@suid/system/styled";
import { StyledProps } from "@suid/system/styledProps";
import { Html5Qrcode, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { t } from "i18next";
import { createEffect, createSignal, ParentComponent } from "solid-js";
import { config } from "../../config";
import { ModalDialogProps } from "../PdfModalDialog";

const QrScanner = styled("div", {
    name: "QrScanner",
})(
    ({ theme }) => ({
        "max-width": "600px",
        "max-height": "600px",
        width: "100%",
        height: "100%",
        background: theme.palette.grey?.["200"],
        borderRadius: theme.spacing(2),
        overflow: "hidden",
        "aspect-ratio": "1/1",
        "& video": {
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
        },
    } as StyledProps),
);

export const QrScannerDialog: ParentComponent<ModalDialogProps> = (props) => {
    const qrScannerId = "qrScanner";
    const theme = useTheme();
    const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));
    const [qrScanner, setQrScanner] = createSignal<Html5Qrcode | null>(null);

    let onResize!: (event: Event) => Promise<void>;

    const close = async (event: Event): Promise<void> => {
        window.removeEventListener("resize", onResize);
        props.onClose?.(event, "closeButtonClick");
    };

    onResize = async (event: Event): Promise<void> => {
        // Always remove event to prevent it from persisting in memory.
        window.removeEventListener("resize", onResize);

        console.log("For the person that is wondering why the qr-scanner just closed. It is because the screen changed size. This would cause the scanner to look wierd. We wouldn't want that now do we.");
        await close(event);
    };

    const redirectToManualQrPage = (): void => {
        window.location.href = `${config.app.authority.replace(/\/$/, "")}/device`;
    };

    const onScanSuccess = async (decodedText: string): Promise<void> => {
        if (!decodedText.startsWith(`${config.app.authority.replace(/\/$/, "")}/device`)) {
            console.log(`Invalid url. Value was ${decodedText}`);
            return;
        }
        try {
            if (!(new URL(decodedText).searchParams.has("code"))) {
                console.log("No 'code' parameter in query parameters");
            }
            await close(undefined as unknown as Event);
            window.location.href = decodedText;
        } catch {
            console.log(`qr-code value not a url. Was: ${decodedText}`);
        }
    };

    createEffect(async (): Promise<void> => {
        if (!props.open || qrScanner()) {
            if (qrScanner() && !props.open) {
                await qrScanner()?.stop();
                setQrScanner(null);
            }
            if (qrScanner() && props.open) {
                window.addEventListener("resize", onResize);
            }
            return;
        }
        const scanner = new Html5Qrcode(
            qrScannerId,
            {
                verbose: false,
                formatsToSupport: [
                    Html5QrcodeSupportedFormats.QR_CODE,
                ],
            },
        );
        try {
            await scanner.start(
                { facingMode: "environment" },
                {
                    fps: 10,
                    qrbox: (viewfinderWidth, viewfinderHeight) => {
                        const smallest = Math.min(viewfinderWidth - 50, viewfinderHeight - 50);
                        return {
                            width: smallest,
                            height: smallest,
                        };
                    },
                },
                onScanSuccess,
                undefined,
            );
            setQrScanner(scanner);
        } catch (error) {
            console.debug("Failed to start scanner:");
            console.error(error);
            await close(undefined as unknown as Event);
            redirectToManualQrPage();
        }
    });

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            hideBackdrop
            component={Modal}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: isSmDownBreakpoint()
                    ? {
                        m: 0,
                        width: "100%",
                        height: "100%",
                        borderRadius: 0,
                    }
                    : {
                        borderRadius: 2.5,
                    },
            }}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2,
                    display: "flex",
                }}
                id="customized-dialog-title"
            >
                {props.title}
            </DialogTitle>
            <DialogContent>
                <QrScanner id={qrScannerId} sx={{ mb: 2 }}/>
                <div
                    style={{
                        display: "flex",
                        "justify-content": "space-between",
                        width: "100%",
                        "row-gap": "16px",
                        "flex-wrap": "wrap-reverse",
                    }}
                >
                    <Button
                        data-testid="ScannerCancelButton"
                        color="secondary"
                        variant="outlined"
                        onClick={(event) => void close(event)}
                        sx={theme.mixins.buttonOutlined}
                    >{t("general.btn.cancel")}</Button>
                    <Button
                        data-testid="ManualQrButton"
                        color="secondary"
                        variant="contained"
                        onClick={() => redirectToManualQrPage()}
                        sx={theme.mixins.button}
                    >{t("general.btn.manual_qr_input")}</Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default QrScannerDialog;
