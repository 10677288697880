import { Paper, Typography, useMediaQuery, useTheme } from "@suid/material";
import { t } from "i18next";
import { createSignal, lazy, type ParentComponent } from "solid-js";

import { AppFrameStyled } from "./AppFrame.styled";
import { type AppFrameProps } from "./index";
import { config } from "../../config";
import { MarkdownLink } from "../../utilities/Parser/MarkdownLink";

const PdfModalDialog = lazy(async () => import("../PdfModalDialog/PdfModalDialog"));

export const AppFrame: ParentComponent<AppFrameProps> = (props) => {
    const [pdf, setPdf] = createSignal<string>();

    const handleClose = (): void => {
        setPdf(undefined);
    };

    const theme = useTheme();
    const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    const version = config.version.env && config.version.env !== "production"
        ? `${config.version.env} - ${config.version.number}`
        : config.version.number;

    return (<AppFrameStyled {...props}>
        <div class="frame">
            <Paper sx={{
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                ...theme.mixins.paper,
                ...(isSmDownBreakpoint() && { borderRadius: 0 }),
            }}>
                {props.children}
            </Paper>
            <footer>
                <Typography sx={theme.mixins.typography} variant="caption2" as="span">{t("general.p.version_{version}", { version })}</Typography>
                <MarkdownLink
                    variant="caption2"
                    color="inherit"
                    sx={{ ...theme.mixins.typography, pl: 1.5, pr: 2.5 }}
                    text={t("general.a.privacy_and_terms")}
                    onClick={(event) => { setPdf(event.target.href); event.preventDefault(); }}
                />
            </footer>
            {pdf() ? <PdfModalDialog
                pdfResource={pdf()}
                open
                onClose={handleClose}
            /> : null}
        </div>
    </AppFrameStyled>);
};
