import { useSearchParams } from "@solidjs/router";
import { Box, Table, TableCell, TableRow, Typography, useTheme } from "@suid/material";
import { t } from "i18next";
import { Component, createEffect, createSignal, JSX } from "solid-js";
import { Button } from "../components/Button";
import { LoginDialog } from "../components/LoginDialog";
import { ThrottledButton } from "../components/ThrottledButton";
import { config } from "../config";
import { ErrorSeverity, ExceptionIdentifier, Stage, type StageError } from "../domain";
import { useInteractionId, useStageData } from "../hooks";
import { getHighestSeverityChallengeException, getStageErrorSeverity } from "../utilities";

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- error can be anything.
export const Error: Component<{ stageData?: StageError | Stage; exception?: any }> = (props) => {
    const theme = useTheme();
    const { refetch, resetState } = useStageData();
    const [throttleTime, setThrottleTime] = createSignal(1);
    const [params] = useSearchParams();

    // When the apps page is configured go to the apps page when no session is started.
    if (!useInteractionId().interactionId() &&
        !!config.app.authority &&
        !!config.app.clientId
    ) {
        const url = new URL(window.location.href);
        let redirectUrl: URL = new URL(`${config.baseApi.replace(/\/$/, "")}/apps`);
        // check if it can redirect directly to the special apps domain.
        if (url.pathname === "/apps" && !config.debug.mockResponse) {
            const expectedUrl = new URL(config.app.url ?? config.baseApi);
            if (expectedUrl.origin !== url.origin) {
                redirectUrl = expectedUrl;
                if (new URL(config.baseApi).origin === redirectUrl.origin) {
                    redirectUrl.pathname = "/apps";
                }
            }
        }
        window.location.replace(redirectUrl);
    }

    const [severity, setSeverity] = createSignal(ErrorSeverity.NONE);
    const [description, setDescription] = createSignal<JSX.Element>();
    const [message, setMessage] = createSignal<JSX.Element>();

    const updateContent = (id: ExceptionIdentifier, details?: string, reference?: string): void => {
        switch (id) {
            case ExceptionIdentifier.NO_FLOW:
            case ExceptionIdentifier.NO_INTERACTION_ID:
                setDescription(t("error.desc.sign_in_expired"));
                setMessage(<Box sx={{ pt: 2, pb: 3 }}>
                    {t("error.p.no_stage_affiliated")}
                </Box>);
                break;

            // TODO: translate
            case ExceptionIdentifier.ABORT_ERROR:
            case ExceptionIdentifier.INVALID_CREDENTIALS:
            case ExceptionIdentifier.AUTHENTICATION_REQUIRED:
            case ExceptionIdentifier.INVALID_INPUT:
            case ExceptionIdentifier.HTTP_429:
            case ExceptionIdentifier.INVALID_STATE:
            case ExceptionIdentifier.HTTP_409:
            case ExceptionIdentifier.HTTP_422:
            case ExceptionIdentifier.HTTP_500:
            case ExceptionIdentifier.HTTP_502:
            case ExceptionIdentifier.HTTP_504:
            case ExceptionIdentifier.NETWORK_ERROR:
            case ExceptionIdentifier.JSON_ERROR:

            case ExceptionIdentifier.UNKNOWN:
            default:
                setDescription(t("error.desc.sign_in_failed"));
                setMessage(<>
                    <Box sx={{ pt: 2, pb: 3 }}>
                        <Table>
                            <TableRow>
                                <TableCell>{t("error.p.code_identifier")}</TableCell>
                                <TableCell>
                                    <Typography sx={theme.mixins.typography} variant="body2">{details ?? id}</Typography>
                                </TableCell>
                            </TableRow>
                            {reference && <TableRow>
                                <TableCell>{t("error.p.correlation_id")}</TableCell>
                                <TableCell>
                                    <Typography sx={theme.mixins.typography} variant="body2">{reference}</Typography>
                                </TableCell>
                            </TableRow>}
                        </Table>
                    </Box>
                    <Box sx={{ pt: 2, pb: 3 }}>
                        {params.reason ? t(`error.desc.${params.reason}`) : t("error.desc.something_went_wrong")}
                    </Box>
                </>);
                break;
        }
    };

    // Remove interactionId when no flow is triggered.
    createEffect(() => {
        if (props.exception || !props.stageData) {
            // Unhandled exception or stand-alone.
            const urlParams = new URLSearchParams(window.location.search);

            // @ts-expect-error -- fall back value is supplied.
            setSeverity(ErrorSeverity[urlParams.get("severity")] as ErrorSeverity ?? ErrorSeverity.FATAL);

            // Try and read the exception object
            updateContent(
                // @ts-expect-error -- fall back value is supplied.
                ExceptionIdentifier[urlParams.get("error")] as ExceptionIdentifier ?? ExceptionIdentifier.UNKNOWN,
                undefined,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- Assume exception object
                urlParams.get("correlation_id") ?? props.exception?.name ?? props.exception,
            );

            // Show the error on the console, if present
            if (props.exception) console.error(props.exception);
        } else if ("id" in props.stageData) {
            // StageError
            setSeverity(getStageErrorSeverity(props.stageData));

            updateContent(props.stageData.id, undefined, props.stageData.reference);
        } else if ("exceptions" in props.stageData) {
            // Stage with exceptions

            const exception = getHighestSeverityChallengeException(props.stageData.exceptions);
            setSeverity(getStageErrorSeverity(props.stageData));
            updateContent(exception?.id ?? ExceptionIdentifier.UNKNOWN, undefined, exception?.detail);
        }

        if (props.stageData && (!("type" in props.stageData)) && props.stageData.id === ExceptionIdentifier.NO_FLOW) {
            const storedInteractionId = sessionStorage.getItem("interactionId");
            if (!storedInteractionId) {
                window.location.href = "/apps";
            }
            sessionStorage.removeItem("interactionId");
        }
    });

    const primaryAction = (): void => {
        if (severity() === ErrorSeverity.FATAL) {
            // Just go back to the apps page.
            window.location.href = "/apps";
        } else {
            // Increase throttle time and retry.
            setThrottleTime(throttleTime() * 2);
            refetch();
        }
    };

    const primaryActionLabel = (): string => {
        switch (severity()) {
            case ErrorSeverity.NONE:
            case ErrorSeverity.DEBUG:
            case ErrorSeverity.INFO:
                return t("general.btn.continue");

            case ErrorSeverity.WARNING:
            case ErrorSeverity.ERROR:
                return t("general.btn.retry");

            case ErrorSeverity.FATAL:
            default:
                return t("general.btn.back_to_app_overview");
        }
    };

    return (<LoginDialog
        id="Error"
        title={t("error.title.whoops")}
        description={description()}
        secondaryButton={severity() !== ErrorSeverity.FATAL && <Button
            data-testid="BackButton"
            color="secondary"
            variant="contained"
            onClick={() => void resetState()}
            sx={theme.mixins.button}
        >
            {t("general.btn.back")}
        </Button>}
        primaryButton={<ThrottledButton
            disableTime={throttleTime()}
            onClick={primaryAction}
            variant="contained"
            color="secondary"
        >
            {primaryActionLabel()}
        </ThrottledButton>}
        errors={[]}
    >
        <Typography variant="body1" gutterBottom role="alert">
            {message()}
        </Typography>
    </LoginDialog>);
};

export default Error;
