import { CircularProgress, Stack, Typography } from "@suid/material";
import { t } from "i18next";
import { ParentComponent } from "solid-js";
import { LoginDialog } from "../LoginDialog";

export const Loader: ParentComponent = (props) => <LoginDialog id="loading" errors={[]} >
    <Stack direction="column" alignItems="center" gap={2}>
        <CircularProgress/>
        {props.children ?? <Typography>{t("general.title.loading")}</Typography>}
    </Stack>
</LoginDialog>;
