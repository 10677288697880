import { styled } from "@suid/material";
import { createEffect, createSignal, type Component } from "solid-js";
import { type FlagProps } from "./index";

const FlagStyled = styled("img")<FlagProps>(
    () => ({
        width: "16px",
        border: "1px solid rgba(0,0,0,0.2)",
        height: "auto",
        aspectRatio: "4/3",
    }),
);

// Flags from https://flagicons.lipis.dev/, https://github.com/lipis/flag-icons
export const Flag: Component<FlagProps> = (props) => {
    const [flag, setFlag] = createSignal<string>();
    createEffect(() => {
        if (props.locale) {
            setFlag(props.locale?.split("-").pop()?.toLowerCase());
        }
    });

    return <>{flag() ? <FlagStyled role="figure" src={`/assets/flags/spritemap.svg#flags-${flag()}-view`} onerror={() => setFlag("xx")} /> : undefined}</>;
};
