import type { Component } from "solid-js";
import { useInteractionId } from "src/hooks";
import { config } from "../config";

/**
 * Redirect "component".
 *
 * This component will not output any html, but instead
 * will redirect to the provided URL
 * or reload the page if no URL is provided.
 *
 * @param props The properties object.
 * @param props.url The URL to redirect to.
 * @param props.finalize Should we finalize and clear the interaction ID
 * @returns False.
 */
export const Redirect: Component<{ url: string; finalize?: boolean }> = ({ url, finalize = false }) => {
    const { clearInteractionId } = useInteractionId();

    if (finalize) {
        clearInteractionId();
    }

    if (!url) {
        window.location.reload();
    } else if (config.debug.URLHistory) {
        window.location.href = url;
    } else {
        // Prevent browser history clutter
        window.location.replace(url);
    }
    return false;
};

export default Redirect;
