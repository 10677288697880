import { Box, Stack, Typography, useTheme } from "@suid/material";
import i18next, { changeLanguage } from "i18next";
import { createEffect, createSignal, onMount, type ParentComponent } from "solid-js";
import { LoginDialogProps } from "./Types";
import prowise_svg from "../../assets/prowise.svg";
import { config } from "../../config";
import { type BCP47 } from "../../utilities/defaultLocales";
import { AppFrame } from "../AppFrame";
import { DebugErrors } from "../DebugErrors";
import { LocaleMenu } from "../LocaleMenu";

export const LoginDialog: ParentComponent<LoginDialogProps> = (props) => {
    const theme = useTheme();
    const [columns, setColumns] = createSignal(1);
    let frameRef: HTMLElement | undefined;

    // NOTE: Sequential mounts don't do autofocus; `keyed` properties have no effect
    // and we don't have a Dialog to set `disableRestoreFocus` on.
    // Also, due to some reactive properties, the UI is not fully drawn immediately,
    // so we need to delay the actual focus.
    onMount(() => {
        requestAnimationFrame(() => {
            document.title = typeof props.title === "string" ? `${props.title} – ${config.version.productName}` : config.version.productName;
            const element = document?.querySelector<HTMLInputElement>("[autofocus]");
            element?.focus();
        });
    });

    createEffect(() => {
        const frame = document.querySelector(".frame");
        if (!frame) return undefined;

        // Attach observer
        const observer = new ResizeObserver(() => {
            setColumns(Number(window.getComputedStyle(frame).getPropertyValue("--frameColumns") || "1"));
        });

        observer.observe(frame);
        return () => { observer.disconnect(); };
    });

    return (
        <AppFrame
            ref={frameRef}
            fullWidth={props.fullWidth}
            minContentWidth={props.minContentWidth}
            data-testid={props.id}
        >
            <Box sx={columns() === 1 ? { position: "relative", textAlign: "center", left: 0, right: 0, pb: 4, px: 0 } : { position: "absolute", left: 0, right: 0, pb: 0, pt: "6px", px: 4 } }>
                <img role="banner" height="30px" src={prowise_svg} />
                <LocaleMenu
                    variant="text"
                    showFlag
                    sx={{ position: "absolute", p: 0, right: theme.spacing(columns() === 1 ? -3.5 : 4), top: theme.spacing(columns() === 1 ? -3.5 : 0), minWidth: theme.spacing(5) }}
                    name="Language"
                    default={i18next.language as BCP47}
                    handleChange={(locale: string) => {
                        // Optionally, check if the chosen language exists in i18n.
                        const changeLang = async (): Promise<void> => {
                            await changeLanguage(locale);
                            document.location.reload();
                        };
                        void changeLang();
                        return locale;
                    }}
                />
            </Box>
            {props.title ? <Typography data-testid="title" sx={{ ...theme.mixins.typography, mb: 4, px: columns() === 1 ? 0 : "160px" }} variant={props.titleVariant ?? "h2"} align="center" color="primary">{props.title}</Typography> : null}
            {props.link ? <Typography sx={{ ...theme.mixins.typography, mt: -3, mb: 4.5 }} variant="body2" align="center" color="primary">{props.link}</Typography> : null}
            {props.description ? <Typography sx={{ ...theme.mixins.typography, mb: 3 }} variant="body2" align="center" color="primary">{props.description}</Typography> : null}
            <Box sx={{ flex: props.fullHeight ? "auto" : undefined, display: "flex", flexDirection: "column" }} class="content">
                {props.children}
            </Box>

            <DebugErrors errors={props.errors} />

            <Stack
                class="footer"
                justifyContent="space-between"
                alignItems="center"
                rowGap={2}
                columnGap={2}
                flexWrap="wrap-reverse"
                direction="row"
                sx={{ mt: 2 }}
            >
                <span>
                    {props.secondaryButton}
                </span>
                <span>
                    {props.primaryButton}
                </span>
            </Stack>
        </AppFrame>
    );
};
